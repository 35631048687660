import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Flock } from 'features/flock'
import { CircleNoise } from 'features/circleNoise';
import { Spiro } from 'features/spiro';
import { Biog } from './features/biog';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter
} from "react-router-dom";

import './index.scss'

import githubLogo from './images/logo-github.svg'
import instaLogo from './images/logo-instagram.svg'
import mailLogo from './images/mail-open-outline.svg'
import linkedInLogo from './images/logo-linkedin.svg'

const imageStyle = { width: 30, padding: '1rem' }




const router = createHashRouter([
  {
    path: "/",
    element: <Flock />,
  },
  {
    path: "/circle",
    element: <CircleNoise />,
  },
  {
    path: "/spirograph",
    element: <Spiro />,
  },

  {
    path: "/biog",
    element: <Biog />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>

      <div id="nav-holder">
        <nav>
          <ul>
            <li className='menu-item'>
            <a href={`/`}>Graham Clapham – developer:</a>
            </li>
            <li className='menu-item'>
              <a href={`/`}>flock</a>
            </li>
            <li className='menu-item'>
              <a href={`/#/circle`}>vortex</a>
            </li>
            <li className='menu-item'>
              <a href={`/#/spirograph`}>spirograph</a>
            </li>

            <li className='menu-item'>
              <a href={`/#/biog`}>about</a>
            </li>
            <li>
              <a href='https://www.instagram.com/grahamclapham/' target='_blank' rel="noreferrer" >
                <img alt='Instagram' style={imageStyle} src={instaLogo} />
              </a>
            </li>
            <li>
              <a href='https://github.com/mrClapham' target='_blank' rel="noreferrer" >
                <img alt="Github" style={imageStyle} src={githubLogo} />
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/grahamclapham' target='_blank' rel="noreferrer" >
                <img alt="Mail" style={imageStyle} src={linkedInLogo} />
              </a>
            </li>
            <li>
              <a href='mailto:clapham.graham@gmail.com' >
                <img alt="Mail" style={imageStyle} src={mailLogo} />
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <RouterProvider router={router} />

      {/* <App /> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
