
import { useEffect, useRef } from 'react'
import { noiseOrbitSketch } from 'p5-typescript-boilerplate';
import p5 from 'p5'

import './circle.scss'


export const CircleNoise = () => {
    const divRef = useRef<HTMLDivElement>(null);
    const config = {
        width: 1200,
        height: 800,
        fullscreen: false,
        fill: 'rgba(9255,255,255,.5)',
        stroke: 'rgba(255,10,100,1)',
        backgroundfill: 'rgba(0,0,0,.02)',
        strokeWeight: 1,
        xDist: .3,
        yDist: 2,
        amountToNudge: 0.15
    }

    useEffect(() => {


        if (divRef.current) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const noiseOrbitSketchInstance = new p5(noiseOrbitSketch(config), divRef.current as HTMLDivElement);
        }
    })

    return <><div className='circle-holder'>
        <div id='circleHolder' ref={divRef} />
    </div>
        <div className="biog">
            <p><span style={{ fontWeight: 600 }}>NOISE VORTEX </span></p>
            <span>
                <p>Perlin noise is used to make a funky, morphing vortex.</p>
                <p>Fun fact, Perlin noise is an algorithm for producing pseudo random arrays of numbers and was developed for the movie Tron. </p>
                <p>In 1997, Ken Perlin was awarded an Academy Award for Technical Achievement for creating the algorithm, the citation for which read: To Ken Perlin for the development of Perlin Noise, a technique used to produce natural appearing textures on computer generated surfaces for motion picture visual effects.</p>
            </span>
        </div>
    </>
}