
import { useEffect, useRef } from 'react'
import { flockingSketch } from 'p5-typescript-boilerplate'
import p5 from 'p5'

import './flock.scss'


export const Flock = () => {
    const divRef = useRef<HTMLDivElement>(null);
    const guiRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        let flockRef: p5 | null = null

        const flockConfig = {
            width: 1000,
            height: 1000,
            depth: 10,
            numBoids: 100,
            fullscreen: true,
            fill: 'rgba(255,0,255,1)',
            stroke: 'rgba(255,0,25,1)',
            backgroundfill: 'rgba(0,0,0,.02)',
            strokeWeight: 1,
            canvasDepth: 25
        }

        const boidConfig = {
            r: 1.0,
            maxspeed: 6.5,
            maxforce: 0.25,
            coheisionDistance: 50,
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (!flockRef && divRef.current) {
            if (divRef.current?.childNodes.length < 1) {
                flockRef = new p5(flockingSketch(flockConfig, boidConfig), divRef.current as HTMLDivElement)
            }
        }
    })


    return <div className='main'>
        <div id='gui-holder' ref={guiRef}></div>
        <div id='flockHolder' ref={divRef} />

        <div className="biog">
            <p><span style={{ fontWeight: 600 }}>FLOCK </span></p>
            <span>
                <p>Based on Reynolds flocking algorithm <a href="https://en.wikipedia.org/wiki/Boids" target='_blank'> ‘Boids’  </a>.</p>
                <p>The dots follow your mouse.</p>
                <p>Hold the mouse down and you will repel them</p>
            </span>
        </div>
    </div>
}