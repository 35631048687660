export interface IPoints {x:number, y:number}
export interface ISpiroConfig {
    width:number,
    height:number,
    centerX: number,
    centerY: number,
    radius: number,
    radius2: number,
    radius3: number,
    angle: number,
    gearRatio1: number,
    increment: number,
    guideThickness: number,
    mainColor: string,
    mainLineThickness: number,
    mainOpacity: number,
    innerColour: string,
    guideColourOuter: string,
    guideColourInner: string,
    innerLineThickness: number,
    showEndDots: boolean,
    currentAngle: number,
    innerAngle: number,
    playing: boolean,
}

export const spiroConfig =   {
    width:1000,
    height:1000,
    centerX: 200,
    centerY: 200,
    radius: 420,
    radius2: 320,
    radius3: 100,
    angle: 0,
    gearRatio1: 0,
    increment: .5,
    guideThickness: .5,
    // colours
    mainColor: 'rgba(0,99,255,0.5)',
    mainLineThickness: .5,
    mainOpacity: 0.5,
    innerColour: 'rgba(255,0,255,0.2)',
    guideColourOuter: '#151515',
    guideColourInner: '#000000',
    innerLineThickness: .25,
    showEndDots: false,
    currentAngle: 0,
    innerAngle: 0,
    playing: true,
  }

  const makeCanvas = (width: number, height: number, opt_class?: string):HTMLCanvasElement => {
    var canvas = document.createElement("canvas");
    canvas.className        = opt_class ? opt_class : "myClass";
    canvas.width            = width;
    canvas.height           = height;
    canvas.style.position   = 'absolute';
    canvas.style.top        = '0';
    canvas.style.left       = '0';

    return canvas
};

const drawCircle = function(ctx:CanvasRenderingContext2D,x:number ,y:number,r:number, color:string, border:boolean=false, guideThickness:number = 2 ):void{
    ctx.beginPath();
    ctx.arc(x,y,r,0,2*Math.PI);
    ctx.fillStyle = '#ff00ff';
    ctx.strokeStyle=color;
    ctx.lineWidth = guideThickness;
    !border ? ctx.fill() : ctx.stroke();
}

/*
* Convert degrees to radians
* @param value
* @returns {number}
*/
const toRadians = (value: number) =>{
   if(!isNaN(value)){
       return  value * Math.PI/180
   }else{
       throw new Error("Please provide a number value to convert to radians @function toRadians(value). ");
   }
}

/**
 * Converts a radius and angle into x and y positions, relative to the center of a circle.
 * @param radius
 * @param angle
 */
const circleToXY =  (centre:IPoints, radius:number, angle: number): IPoints =>{
    var points = {x:0, y:0};

    if(!isNaN(centre.x) && !isNaN(centre.y) && !isNaN(radius) && !isNaN(angle) ){
        var radians =  toRadians(angle);
        points.x = centre.x + (radius * Math.cos(radians));
        points.y = centre.y + (radius * Math.sin(radians));
    }

    return points;
};

export class SpiroGraph {
    private target: HTMLDivElement;

    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D | null;

    private canvasGuides: HTMLCanvasElement;
    private ctxGuides: CanvasRenderingContext2D | null;

    private canvasLines: HTMLCanvasElement;
    private ctxLines: CanvasRenderingContext2D | null;

    private points: IPoints;
    private points2: IPoints;
    private points3: IPoints;


    config:  ISpiroConfig;
   
    constructor(target: HTMLDivElement, config?: Partial<ISpiroConfig>) {
      this.config = {...spiroConfig, ...config};
      this.target = target;

      this.canvas = this.target.appendChild(makeCanvas(this.config.width, this.config.height));
      this.canvasGuides = this.target.appendChild(makeCanvas(this.config.width, this.config.height));
      this.canvasLines = this.target.appendChild(makeCanvas(this.config.width, this.config.height, "linesCanv"));
      this.ctx = this.canvas.getContext("2d") ? this.canvas.getContext("2d")  : null;
      this.ctxGuides =  this.canvasGuides.getContext("2d") ? this.canvasGuides.getContext("2d") : null;
      this.ctxLines = this.canvasLines.getContext("2d") ? this.canvasLines.getContext("2d") : null;

    this.points = {x:0, y:0};
    this.points2 = {x:0, y:0};
    this.points3 = {x:0, y:0};

      this.play()
      const _draw = this.draw.bind(this);
      const _getConfig = this.getConfig.bind(this);
      function render (){
       _getConfig().playing &&  _draw();
        requestAnimationFrame(render)
    }
      render()
    }
    drawSpiral (ctx:CanvasRenderingContext2D, centerX: number, centerY: number, radius: number, angle: number): void{
        this.config.currentAngle = 0;    
        this.points = circleToXY({x:this.getCentre().x, y:this.getCentre().x}, this.config.radius,  this.config.currentAngle);
        this.points2 = circleToXY({x:this.points.x, y:this.points.y}, this.config.radius2,  this.config.currentAngle);
        this.points3 = circleToXY({x:this.points2.x, y:this.points2.y}, this.config.radius2,  this.config.currentAngle);
    };
    getCentre (){
        return {x: this.config.width/2, y: this.config.height/2};
    };
    plotPoints(){
        this.config.gearRatio1 = (2 * Math.PI * this.config.radius-this.config.radius2) /  (2 * Math.PI * this.config.radius2);
        this.points = circleToXY({x:this.getCentre().x, y:this.getCentre().y}, this.config.radius-this.config.radius2, this.config.currentAngle);
        this.points2 = circleToXY({x:this.points.x, y:this.points.y}, this.config.radius2, this.config.innerAngle * this.config.gearRatio1);
        this.points3 = circleToXY({x:this.points2.x, y:this.points2.y}, -this.config.radius3, this.config.innerAngle * this.config.gearRatio1);
    };
    getConfig():ISpiroConfig {
        return this.config
    }

    draw():void{
        this.plotPoints()
        this.drawSpiro();
    }
    setRadius(value: number){
        this.clear();
        this.config.radius = value
    }

    setRadius2(value: number){
        this.clear();
        this.config.radius2 = value
    }

    setRadius3(value: number){
        this.clear();
        this.config.radius3 = value
    }



    drawSpiro(){
        if(this.ctx){
            if(!this.config.showEndDots){
                drawCircle(this.ctx, this.points2.x, this.points2.y, .75, '#ff0000', false );
            }else{
                this.ctx.globalCompositeOperation = "source-out";
        
            }
            // This draws the radiating lines fron the two dots - inner and outer
            this.ctx.moveTo(this.points3.x, this.points3.y);
            this.ctx.lineTo(this.points2.x, this.points2.y);
            this.ctx.strokeStyle = this.config.innerColour;
            this.ctx.lineWidth = this.config.innerLineThickness;
            this.ctx.stroke();
    
  
        }

        if(this.ctxLines){
            this.ctxLines.strokeStyle = this.config.mainColor;
            this.ctxLines.lineWidth =this.config.mainLineThickness ;
            this.ctxLines.lineTo(this.points3.x, this.points3.y);
        //    this.ctxLines.setLineWidth(1);
            //globalCompositeOperation prevents the line becoming progressively darker
            this.ctxLines.globalCompositeOperation = "source-out";
            this.ctxLines.stroke();
            this.config.currentAngle += this.config.increment;
            this.config.innerAngle -= this.config.increment;
        }
        // inner circle
            // The dot at the end of the radiating line
 
        };
   
    play(value: boolean = true):void {
        this.config.playing = value;
        console.log("PLAYING ",this.config.playing)
    }
    getPlaying(){
        return this.config.playing
    }
    setPlaying(value: boolean){
        this.config.playing = value;
    }
    pause():void{
        this.config.playing = false;
    }
    clear(){
        if(this.ctx){
            this.ctx.clearRect(0, 0, this.config.width, this.config.height);
            this.ctx.beginPath();
          }
          if(this.ctxGuides){
            this.ctxGuides.clearRect(0, 0, this.config.width, this.config.height);
            this.ctxGuides.beginPath();
          }
          if(this.ctxLines){
            this.ctxLines.clearRect(0, 0, this.config.width, this.config.height);
            this.ctxLines.beginPath();
          }


    }
  }
   
