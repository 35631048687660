import { useEffect, useRef } from 'react';
import { SpiroGraph } from './SpiroClass';
import GUI from 'lil-gui';


import './spiro.scss'

export const Spiro = () => {
    const divRef = useRef<HTMLDivElement>(null);
    const guiRef = useRef<HTMLDivElement>(null);

    const obj = {
        radius: 420,
        radius2: 320,
        radius3: 100,
        playing: true
    }


    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const spiro = new SpiroGraph(divRef.current as HTMLDivElement, {})
        const gui = new GUI();
        gui.add(guiRef.current as HTMLDivElement, 'spirograph');
        gui.add(obj, 'radius', 40, 800, 10); // min, max
        gui.add(obj, 'radius2', 40, 800, 10); // min, max
        gui.add(obj, 'radius3', 40, 800, 10); // min, max
        gui.add(obj, 'playing'); 	// checkbox


        gui.onChange(event => {
            console.log("event.property  ", event.property)// string, name of property
            console.log("event.value     ", event.value)// new value of controller
            if (event.property === 'radius') {
                spiro.setRadius(event.value);
            }
            if (event.property === 'radius2') {
                spiro.setRadius2(event.value);
            }
            if (event.property === 'radius3') {
                spiro.setRadius3(event.value);
            }

            if (event.property === 'playing') {
                spiro.setPlaying(Boolean(event.value));
            }

        });

        return ()=>{
            gui.destroy();
        }
    })

    return <div className='main'>
        <div id='gui-holder' ref={guiRef}></div>
        <div id='spiro-holder' ref={divRef}></div>
        <div className="biog">
            <p><span style={{ fontWeight: 600 }}>SPIROGRAPH </span></p>
            <span>
                <p>I always loved the Hasbro Spirograph as a kid.</p>
                <p>This is my TypeScript recreation.</p>
                <p>Change the radii to get different designs.</p>
            </span>
        </div>

    </div>
}