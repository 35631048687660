
import { useEffect } from 'react'

import githubLogo from '../../images/logo-github.svg'
import instaLogo from '../../images/logo-instagram.svg'
import mailLogo from '../../images/mail-open-outline.svg'
import linkedInLogo from '../../images/logo-linkedin.svg'



export const Biog = () => {


    useEffect(() => {

    })

    const imageStyle = { width: 30, padding: '1rem' }

    return <div className='search-box-container'>
        <div className="biog-main">
            <p><span style={{ fontWeight: 600 }}>Graham Clapham — </span><span style={{ fontWeight: 400 }}>Developer</span></p>
            <span style={{ fontWeight: 300 }}>
                <p>I’m Graham.</p>
                <p>I live in London and I make things with code.</p>
                <p>I’ve worked for Warner Bros. Discovery, Lloyds Banking Group, Thomson Reuters, S&P Global, Deutsche Bank, Unilever and OpenFin amongst others.</p>
                <p>I love JavaScript, TypeScript, React, Svelte and anything which puts pixels on screens.</p>
                <p>Checkout my GitHub, InstaGram, LinkedIn or just say hello.</p>
            </span>
            <a href='https://www.instagram.com/grahamclapham/' target='_blank' rel="noreferrer">
                <img alt='Instagram' style={imageStyle} src={instaLogo} />
            </a>
            <a href='https://github.com/mrClapham' target='_blank' rel="noreferrer">
                <img alt="Github" style={imageStyle} src={githubLogo} />
            </a>
            <a href='https://www.linkedin.com/in/grahamclapham' target='_blank' rel="noreferrer">
                <img alt="Mail" style={imageStyle} src={linkedInLogo} />
            </a>
            <a href='mailto:clapham.graham@gmail.com'>
                <img alt="Mail" style={imageStyle} src={mailLogo} />
            </a>
        </div>

    </div>
}